@import "src/reset.scss";
@import "Containers/LandingPage/LandingPage";
@import "Containers/ContactsPage/ContactsPage";
@import "Containers/Services/Services";
@import "Containers/ServiceInfo/ServiceInfo";
@import "variables";

$small: 300px;
$medium: 900px;


//1920×1080 (9.94%)
//1366×768 (6.22%)
//360×640 (5.88%)
//414×896 (4.21%)
//1536×864 (3.94%)
//375×667 (3.74%)

body {
  font-size: 1vw;
  font-family: Montserrat, serif;
  //@media screen and (max-width: $medium) {
  //  background-color: #ecbe21;
  //}
}

.App {
  font-family: Montserrat, serif;
}

.piruz {
  //background-color: #64CBC2;
  background-color: $aquamarine;
}

.lightBlue {
  background-color: $light_blue;
}

.yellow {
  background-color: #ECBE21;
  //background-color: #fbf49c;
}

.green {
  //background-color: #80BF86;
  background-color: #81c085;
}

.purple {
  //background-color: #9B74B3;
  background-color: #6e5aa0;
}


.marginRegularLeft {
  margin-left: 15vw;
}


.dentistsSwiper {
  width: 85vw;
  background-color: transparent;

  .swiper {
    width: 100%;
    height: 100%;
  }

  .swiper-slide {
    text-align: center;
    font-size: 1vw;
    //background: #fff;
    background: transparent;
    flex: 1;

    /* Center slide text vertically */
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
  }

  .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

a {
  text-decoration: none;
}