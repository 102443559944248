.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 1vw;
  background: #fff;

  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.swiper-button-next, .swiper-button-prev, .swiper-pagination-bullet-active {
  color: #ECBE21 !important;
}

.swiper-pagination-bullet-active {
  background-color: #ECBE21 !important;
}


.swiperComponent {
  height: 40vw;
}

.swiperComponentSlideImage {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
}

.swiperComponentWatermark {
  position: absolute;
  top: 2vw;
  right: 3vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.swiperComponentWatermarkText {
  position: absolute;
  top: 3vw;
  right: 1vw;
  z-index: 10;

  p {
    //color: #9B74B3;
    //color: white;
    font-size: 1.8vw;
    font-weight: bold;
  }
}

.swiperComponentIcon {
  position: relative;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  width: 10vw;
  height: 10vw;
  margin-bottom: 1vw;
  z-index: 0;
}